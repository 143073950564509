import React, { Component } from 'react';
import {Grid,TextField} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {Backdrop,CircularProgress} from '@material-ui/core';
import '../index.css';

 
import {
    Button,
    CssBaseline,
    ThemeProvider,
  } from 'horizon-components-react';


  const styles = theme => ({
    root: {
      flexGrow: 1,
      },
      input:{
        background: 'rgba(255, 255, 255, 0.8)',//'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white', 
        height: 48,
        padding: '0 30px',
        alignItems:"center",
        justifyContet:"center"
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  });
  class LoginPage extends Component{

    state={
        //spinnerStatus : false
        username:null,
        password:null,
        successURL:null,failURL:null,applicationName:null, //header values from client
        logintoken:null,roletoken:null, //
        isBackdrop:false,
    }

    handleSubmit=(event) =>{
        event.preventDefault();
        this.sendLogin();
        //this.props.history.push('/user') // TEMPORARY FOR TESTING .
    }
    componentDidMount()
    {
      this.stripURL();

     axios.defaults.headers.post['x-api-key'] = "cOqxo6uxbm7DZFg5CjkaT15KFk82KnUK63OzYOKu";
     axios.defaults.headers.post['Content-Type'] = 'application/json';
    }

    ///strips url for required information (in this case its for the success and fail callback URLs, and the application name)
    stripURL=()=>
    {
      var returnstring=this.props.location.search; //gets query string section of URL
      var urlParams=new URLSearchParams(returnstring) //query string parameters or URL variable interface
      var success= urlParams.get('success_callbackurl') //get the success callback URL
      var fail= urlParams.get('failure_callbackurl') //get the fail callback URL
      var app= urlParams.get('applicationname') //get the fail callback URL
      this.setState({successURL:success,failURL:fail,applicationName:app})
    }

    routeBack=(isSuccess)=>
    {
       //console.log(isSuccess);console.log(this.state.successURL);
      if(isSuccess && this.state.successURL!==null) window.location.assign(this.state.successURL+"?logintoken="+this.state.logintoken+"&roletoken="+this.state.roletoken)
      else if(!isSuccess && this.state.failURL!==null) window.location.assign(this.state.failURL)
      else return;
    }



    //#region DB

    //send login request to SSO API. requires Username, Password,ApplicationName.
    sendLogin=()=>
    {
      this.setState({isBackdrop:true})
      axios.post(`${process.env.REACT_APP_SSO}/api/sso/login`,
      {
        username:this.state.username,
        userpassword:this.state.password,
        applicationname:this.state.applicationName,
        successcallbackURL:this.state.successURL,
        failurecallbackURL:this.state.failURL
      }
      )
      .then(res=> {
        console.log(res)
        this.setState({logintoken:res.data.logintoken,roletoken:res.data.roletoken})
        this.routeBack(true);
        this.setState({isBackdrop:false})
      })
      .catch(err => {
        console.log(err.response); 
        this.routeBack(false)
        this.setState({isBackdrop:false})
      });
    }

    //#endregion


    render(){
    const { classes } = this.props;

    return(
    <ThemeProvider theme="airbus">
    <CssBaseline />
        <div class = "overlay" >

        <Backdrop className={classes.backdrop} open={this.state.isBackdrop} >
          <CircularProgress color="inherit" />
        </Backdrop>


        {/* <img style ={{marginTop: '1rem'}} width="15%" height="15%"   src="/images/airbus_logo_white_svg.svg" alt="react-logo"/> airbus logo */}
        <h1 style = {{marginTop: '10rem', color: "white", fontWeight: 'bold'}}> HoF</h1> 
            <h2 style={{color: '#fbca33', fontWeight: 'bold'}} >Single Sign-On Login</h2>
            <h4 style={{color: '#fbca33', fontWeight: 'bold'}} >{process.env.REACT_APP_VERSION}</h4>{/*so we know what version we are on */}
   
            {/* <Form style={{color: 'white', fontWeight: 'bold'}} */}
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}/> 
              <Grid item xs={12}>
                <TextField className={classes.input}
                hintText="Enter your Username"
                floatingLabelText="Username"
                placeholder="Username"
                label="Username"
                onChange = {(e) => this.setState({username:e.target.value})}
                />
              </Grid>
           {/* <br/> */}
               <Grid item xs={12}>
                <TextField className={classes.input}
                  type="password"
                  hintText="Enter your Password"
                  floatingLabelText="Password"
                  placeholder="Password"
                  label="Password"
                  onChange = {(e) => this.setState({password:e.target.value})}
                  />
                </Grid>
            <Grid item xs={12}/>
              <Grid item xs={12}>
                <Button variant="primary" type="submit" onClick={(e) => this.handleSubmit(e)} style={{color: 'white', fontWeight: 'bold'}} >
                  Login
                </Button>
              </Grid>
            </Grid>
        </div>
    </ThemeProvider>
 
        )}
}


export default  withStyles(styles)(LoginPage);