import React from 'react';
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch, Redirect,Router } from "react-router-dom";
import Login from "./views/LoginPage.js"
import User from "./views/User.js"
// import Admin from "./views/Admin.js"

const hist = createBrowserHistory(); //This is so we can use "back" for client routing


ReactDOM.render(
  < Router history={hist}>
      <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/user" component={User} />
        {/* <Route path="/admin" component={Admin} /> */}
        {/* <Route path="/auth" component={Auth}/> */}
        <Redirect from="/" to="/login"  />
      </Switch>
    </Router>,
    document.getElementById("root")
  );